import React from "react";
import Layout, { HEADER_HEIGHT } from "@features/ui/StandardLayout";
import { Box, Button, Flex, Heading, Text, Image } from "@chakra-ui/react";
import MaxWidthWrapper from "@features/ui/MaxWidthWrapper";
import { useAuth } from "@features/users/hooks";
import Card from "@features/ui/Card";
import Link from "next/link";

function NotFound() {
    const { user } = useAuth();
    return (
        <Layout>
            <Box bg="brand.50">
                <Box>
                    <MaxWidthWrapper>
                        <Flex
                            minH={`calc(100vh - ${HEADER_HEIGHT}px)`}
                            align="center"
                            justify="space-between"
                            direction="row"
                            textAlign="center"
                            color="brand.900"
                        >
                            <Card
                                height="85vh"
                                width="100vw"
                                bg="#fff"
                                mt={6}
                                mb={5}
                                borderRadius={18}
                                as={Flex}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Flex
                                    align="center"
                                    justify="space-between"
                                    direction="column"
                                    textAlign="center"
                                    color="#000"
                                >
                                    <Box>
                                        {user ? (
                                            <Heading>
                                                Hello, {user?.full_name}
                                            </Heading>
                                        ) : (
                                            <Heading>Hello There!</Heading>
                                        )}
                                        <Text>
                                            We&apos;re so sorry, but you&apos;re
                                            not supposed to be here...
                                        </Text>
                                    </Box>
                                    <Box mt={6}>
                                        <Image
                                            src="https://d2v55crl1k4v3v.cloudfront.net/nmvhembam0ns59l96gec66dr5hic"
                                            alt="Page Not Found"
                                            width="500px"
                                            height="350px"
                                            maxW="100%"
                                        />
                                    </Box>
                                    <Box mt={3}>
                                        <Button
                                            size={{ base: "md", md: "lg" }}
                                            colorScheme="brand"
                                            as="a"
                                            href="https://the-bithub.com"
                                        >
                                            Back To Home
                                        </Button>
                                    </Box>
                                </Flex>
                            </Card>
                        </Flex>
                    </MaxWidthWrapper>
                </Box>
            </Box>
        </Layout>
    );
}
export default NotFound;
