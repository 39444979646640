import { Box, BoxProps, useStyleConfig } from "@chakra-ui/react";

interface CardProps extends BoxProps {
    variant?: "outline" | "solid";
}

function Card(props: CardProps) {
    const { variant, ...rest } = props;
    const styles = useStyleConfig("Card", { variant });

    return (
        <Box 
            __css={styles}
            {...rest}
        />
    )
}

export default Card;